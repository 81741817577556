// initial state

const state = () => ({
  createdRoomId: null,
});

//getters
const getters = {
  getCreatedRoomId: (state) => state.createdRoomId,
};

// actions
const actions = {
  updateCreatedRoomId({ commit }, createdRoomId) {
    commit("mutateCreatedRoomId", createdRoomId);
  },
};

// mutations
const mutations = {
  mutateCreatedRoomId(state, roomId) {
    state.createdRoomId = roomId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
