<template>
  <div
    id="footer"
    :class="{ 'items-center': !isStandaloneMode, 'pt-2': isStandaloneMode }"
  >
    <span>Cristaltec Entertainment @{{ new Date().getFullYear() }}</span>
  </div>
</template>

<script>
export default {
  name: "Footer",

  props: {
    isStandaloneMode: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="postcss">
#footer {
  height: calc($footer--height + var(--sab));
  @apply bg-gray-900 px-4 w-full flex justify-end shadow-lg;

  /* @screen md {
      height: calc(2.5vh + var(--sab));
   } */

  span {
    @apply text-white text-xs font-semibold uppercase;
  }
}
</style>
