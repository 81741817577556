import * as Cookies from "js-cookie";
import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import registerInfo from "./modules/registerInfo";
import room from "./modules/room";
import skin from "./modules/skin";

Vue.use(Vuex);

const atCookie = persistedState({
  key: "atCookie",
  paths: ["auth.accessToken"],
  storage: {
    getItem: (key) => Cookies.get(key),
    setItem: (key, value) => {
      if (
        process.env.VUE_APP_IS_SKIN &&
        process.env.VUE_APP_IS_SKIN == "true" &&
        !process.env.VUE_APP_LOCAL_COOKIES
      ) {
        Cookies.set(key, value, {
          expires: parseInt(process.env.VUE_APP_AT_EXPIRATION_DAYS),
          secure: true,
          samesite: "none",
        });
      } else {
        Cookies.set(key, value, {
          expires: parseInt(process.env.VUE_APP_AT_EXPIRATION_DAYS),
          secure: false,
        });
      }
    },
    removeItem: (key) => Cookies.remove(key),
  },
});

const rtCookie = persistedState({
  key: "rtCookie",
  paths: ["auth.refreshToken"],
  storage: {
    getItem: (key) => Cookies.get(key),
    setItem: (key, value) => {
      if (
        process.env.VUE_APP_IS_SKIN &&
        process.env.VUE_APP_IS_SKIN == "true" &&
        !process.env.VUE_APP_LOCAL_COOKIES
      ) {
        Cookies.set(key, value, {
          expires: parseInt(process.env.VUE_APP_RT_EXPIRATION_DAYS),
          secure: true,
          samesite: "none",
        });
      } else {
        Cookies.set(key, value, {
          expires: parseInt(process.env.VUE_APP_RT_EXPIRATION_DAYS),
          secure: false,
        });
      }
    },
    removeItem: (key) => Cookies.remove(key),
  },
});

const authCookie = persistedState({
  key: "authCookie",
  paths: ["auth"],
  storage: {
    getItem: (key) => {
      const cookieString = Cookies.get(key);
      if (cookieString) {
        const value = JSON.parse(cookieString);
        const deviceId = localStorage.getItem("deviceId");
        if (deviceId) {
          value.auth.deviceId = deviceId;
        }
        return JSON.stringify(value);
      }
    },
    setItem: (key, value) => {
      if (value !== 1 && !!value) {
        let val = value;
        let parsed = JSON.parse(value);
        let { deviceId, accessToken, refreshToken, ...rest } = parsed.auth;
        parsed.auth = rest;
        val = JSON.stringify(parsed);

        if (deviceId && deviceId != "null") {
          localStorage.setItem("deviceId", deviceId);
        }
        if (
          process.env.VUE_APP_IS_SKIN &&
          process.env.VUE_APP_IS_SKIN == "true" &&
          !process.env.VUE_APP_LOCAL_COOKIES
        ) {
          Cookies.set(key, val, {
            expires: 16 * 365,
            secure: true,
            samesite: "none",
          }); // in days
        } else {
          Cookies.set(key, val, { expires: 16 * 365, secure: false }); // in days
        }
      }
    },
    removeItem: (key) => Cookies.remove(key),
  },
});

const registerCookie = persistedState({
  key: "registerCookie",
  paths: ["registerInfo"],
  storage: {
    getItem: (key) => Cookies.get(key),
    setItem: (key, value) => {
      if (
        process.env.VUE_APP_IS_SKIN &&
        process.env.VUE_APP_IS_SKIN == "true" &&
        !process.env.VUE_APP_LOCAL_COOKIES
      ) {
        Cookies.set(key, value, {
          expires: 0.2,
          secure: true,
          samesite: "none",
        });
      } else {
        Cookies.set(key, value, { expires: 0.2, secure: false });
      }
    },
    removeItem: (key) => Cookies.remove(key),
  },
});

export default new Vuex.Store({
  state: {
    isProd: process.env.NODE_ENV === "production",
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    room,
    registerInfo,
    skin,
  },
  plugins: [authCookie, registerCookie, atCookie, rtCookie],
});
