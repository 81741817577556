var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "items-center": !_vm.isStandaloneMode,
        "pt-2": _vm.isStandaloneMode,
      },
      attrs: { id: "footer" },
    },
    [
      _c("span", [
        _vm._v("Cristaltec Entertainment @" + _vm._s(new Date().getFullYear())),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }