import { refreshToken } from "@/services/api/authService";
import store from "@/store";
import axios from "axios";

axios.requestsNumber = 0;
const httpClient = (customConfig = { headers: {} }) => {
  let tempAxios = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_API_URL,
  });

  tempAxios.interceptors.request.use(
    (config) => {
      if (customConfig.headers.authorizationToken) {
        config.headers[
          "Authorization"
        ] = `Bearer ${customConfig.headers.authorizationToken}`;
      } else {
        const accessToken = store.getters["auth/getAccessToken"];
        const deviceId = store.getters["auth/getDeviceId"];
        config.headers["Authorization"] = accessToken
          ? `Bearer ${accessToken}`
          : null;
        if (deviceId) {
          config.headers["X-Device-Id"] = deviceId;
        }
      }

      return config;
    },

    (err) => {
      return Promise.reject(err);
    }
  );

  tempAxios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      axios.requestsNumber--;
      let res = err.response;

      if (
        res &&
        res.status === 401 &&
        !!res.data &&
        res.data.code == 38 &&
        !!res.config &&
        !res.config.__isRetryRequest
      ) {
        return new Promise((resolve, reject) => {
          refreshToken({
            userId: store.getters["auth/getUserId"],
            deviceId: store.getters["auth/getDeviceId"],
            refreshToken: store.getters["auth/getRefreshToken"],
          })
            .then((res) => {
              store.dispatch("auth/updateAccessToken", res.data.accessToken);
              store.dispatch("auth/updateRefreshToken", res.data.refreshToken);
              err.config.__isRetryRequest = true;
              err.config.headers.Authorization =
                "Bearer " + res.data.accessToken;
              // retry the request failed after updating accessToken for the request
              resolve(tempAxios(err.config));
            })
            .catch((err) => {
              store.dispatch("auth/cleanUpAuth");
              // redirect to login
              window.location.href = process.env.VUE_APP_BASEURL;
              reject(err);
            });
        });
      }
      return Promise.reject(err);
    }
  );

  return tempAxios;
};

const httpClientFE = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
});

export { httpClient, httpClientFE };
