/*import App from "@/App.vue";
import CustomImage from "@/components/utils/CustomImage";
import router from "@/router";
import store from "@/store";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import Toasted from "@gitlab/vue-toasted";
import { DateTime } from "luxon";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import "swiper/swiper-bundle.min.css";
import Vue from "vue";
import VueAnalytics from "vue-analytics";
import VueCountryCode from "vue-country-code-select";
import VueSignaturePad from "vue-signature-pad";
import "leaflet/dist/leaflet.css";

Vue.component("custom-image", CustomImage);

const optionsToast = {
  position: "bottom-center",
  duration: 5000,
  className: ["error-toast"],
};

Vue.use(Toasted, optionsToast);
Vue.use(VueSignaturePad);
Vue.use(VueCountryCode);
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID,
  router,
});
Vue.use(CKEditor);
SwiperCore.use([Navigation, Pagination]);

Vue.config.devtools = process.env.NODE_ENV != "production";
// Vue.config.devtools = true;
// Vue.config.productionTip = false;

//filters
Vue.filter("money", (value) => {
  if (value !== 0 && !value) {
    return "";
  }

  if (typeof value != "number") {
    return value;
  }

  return new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value / 100);
});

Vue.filter("dateTime", (value) => {
  let lang = "it-IT";
  if (!value) return "-";
  return DateTime.fromISO(value).toLocaleString(
    DateTime.DATETIME_SHORT_WITH_SECONDS,
    {
      locale: lang,
    }
  );
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");*/

import Vue from "vue";
import App from "@/App.vue";
import CustomImage from "@/components/utils/CustomImage";
import router from "@/router";
import store from "@/store";

// Essential imports and setup
Vue.component("custom-image", CustomImage);

Vue.config.devtools = process.env.NODE_ENV !== "production";

// Filters
Vue.filter("money", (value) => {
  if (value !== 0 && !value) {
    return "";
  }

  if (typeof value !== "number") {
    return value;
  }

  return new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value / 100);
});

// Initial Vue instance render
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// Dynamic imports after initial render
setTimeout(() => {
  import("@ckeditor/ckeditor5-vue2").then((CKEditor) => {
    Vue.use(CKEditor.default);
  });

  import("@gitlab/vue-toasted").then((Toasted) => {
    const optionsToast = {
      position: "bottom-center",
      duration: 5000,
      className: ["error-toast"],
    };
    Vue.use(Toasted.default, optionsToast);
  });

  import("luxon").then(({ DateTime }) => {
    Vue.prototype.$DateTime = DateTime;
    Vue.filter("dateTime", (value) => {
      let lang = "it-IT";
      if (!value) return "-";
      return DateTime.fromISO(value).toLocaleString(
        DateTime.DATETIME_SHORT_WITH_SECONDS,
        {
          locale: lang,
        }
      );
    });
  });

  import("swiper/core").then(
    ({ default: SwiperCore, Navigation, Pagination }) => {
      SwiperCore.use([Navigation, Pagination]);
      import("swiper/swiper-bundle.min.css");
    }
  );

  import("vue-analytics").then((VueAnalytics) => {
    Vue.use(VueAnalytics.default, {
      id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID,
      router,
    });
  });

  import("vue-country-code-select").then((VueCountryCode) => {
    Vue.use(VueCountryCode.default);
  });

  import("vue-signature-pad").then((VueSignaturePad) => {
    Vue.use(VueSignaturePad.default);
  });

  import("leaflet/dist/leaflet.css");
}, 0);
