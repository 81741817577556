<template>
  <div
    id="top-bar"
    :class="[$route.name !== 'Login' ? 'justify-between' : 'justify-center']"
  >
    <ModalTemplate
      v-if="showQRCodeModal"
      class="z-50"
      @close="showQRCodeModal = false"
    >
      <template v-slot:header>
        <h3>Proseguire su un altro dispositivo</h3>
      </template>
      <template v-slot:body>
        <p>Accedi a questa pagina su un altro dispositivo:</p>
        <div @click="showQRCodeModal = false">
          <qrcode-vue
            :value="qrCodeURL"
            :size="size"
            render-as="svg"
            foreground="#000000"
            background="white"
            class="p-4 bg-white qr-code rounded-xl shadow-xl w-fit mx-auto mt-4"
          />
        </div>
      </template>
    </ModalTemplate>
    <ModalTemplate
      v-if="showSupportModal"
      class="z-50"
      @close="showSupportModal = false"
    >
      <template v-slot:header>
        <h3>Supporto clienti</h3>
      </template>
      <template v-slot:body>
        <p>
          Per maggiori informazioni non esitare a contattare il nostro supporto
          clienti:
        </p>
        <div class="space-y-2 mt-4">
          <a
            v-if="supportInfo.mail"
            class="flex gap-x-4"
            :href="`mailto:${supportInfo.mail}`"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="1.5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
              /></svg
            >{{ supportInfo.mail }}
          </a>
          <a
            v-if="supportInfo.contact"
            class="flex gap-x-4"
            :href="`tel:${supportInfo.contact}`"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="1.5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
              /></svg
            >{{ supportInfo.contact }}
          </a>
        </div>
      </template>
    </ModalTemplate>
    <button
      v-if="
        ($route.name == 'ConfirmData' || $route.name == 'NewPlayer31') &&
        !isAuthenticated &&
        supportInfo.mail &&
        supportInfo.contact
      "
      class="text-xs h-8 tracking-wide flex items-center gap-x-1.5 font-medium uppercase border border-secondary rounded-lg px-2 lg:px-4"
      :class="{ 'ml-auto': $route.name == 'NewPlayer31' }"
      @click.prevent="showSupportModal = true"
    >
      <svg
        class="w-5 h-5"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 442 442"
        style="enable-background: new 0 0 442 442"
        xml:space="preserve"
        fill="#fff"
      >
        <path
          d="M431.706,232.827h-1.108c-3.863-17.348-17.544-31.03-34.892-34.892v-23.229C395.706,78.373,317.333,0,221,0S46.294,78.373,46.294,174.706v23.229c-17.348,3.863-31.03,17.544-34.892,34.892h-1.108c-5.523,0-10,4.478-10,10s4.477,10,10,10h1.108c3.962,17.796,18.257,31.735,36.242,35.173c4.159,17.647,20.027,30.827,38.926,30.827c22.056,0,40-17.944,40-40v-72c0-22.056-17.944-39.999-40-39.999c-7.384,0-14.303,2.019-20.246,5.523C67.589,88.128,136.482,20,221,20c84.464,0,153.325,68.042,154.674,152.191c-5.881-3.404-12.698-5.363-19.968-5.363c-22.056,0-40,17.943-40,39.999v72c0,22.056,17.944,40,40,40c18.918,0,34.799-13.205,38.938-30.879c6.229-1.232,12.007-3.724,17.062-7.201v44.167c0,18.786-9.134,36.63-25.72,50.244C368.827,389.243,345.999,397,321.706,397h-64.907c-2.082-15.648-9.245-25-19.726-25h-37.5c-19.299,0-35,15.701-35,35s15.701,35,35,35h37.5c10.482,0,17.645-9.352,19.726-25h64.907c60.654,0,110-41.31,110-92.086v-72.087c5.523,0,10-4.478,10-10S437.229,232.827,431.706,232.827z M66.57,206.827c0-11.027,8.972-19.999,20-19.999c11.028,0,20,8.972,20,19.999v72c0,11.028-8.972,20-20,20c-11.028,0-20-8.972-20-20c0-5.522-4.477-10-10-10c-0.047,0-0.092,0.006-0.138,0.007c-0.047,0-0.092-0.007-0.138-0.007c-14.336,0-26-11.663-26-26c0-10.9,6.744-20.248,16.276-24.106v25.996c0,5.522,4.477,10,10,10s10-4.478,10-10V206.827z M234.938,422h-35.366c-8.271,0-15-6.729-15-15s6.729-15,15-15h35.366c1.049,2.163,2.489,7.271,2.489,15S235.987,419.837,234.938,422z M385.706,268.827c-5.523,0-10,4.478-10,10c0,11.028-8.972,20-20,20s-20-8.972-20-20v-72c0-11.027,8.972-19.999,20-19.999s20,8.972,20,19.999v37.89c0,5.522,4.477,10,10,10s10-4.478,10-10V218.83c9.387,3.926,16,13.203,16,23.997C411.706,257.164,400.042,268.827,385.706,268.827z"
        />
      </svg>
      supporto
    </button>
    <div
      v-if="isAuthenticated || $route.name == 'ConfirmData'"
      class="flex ml-auto items-center"
    >
      <button
        v-if="$route.name == 'ConfirmData'"
        class="text-xs h-8 tracking-wide flex items-center gap-x-1.5 font-medium uppercase border border-secondary rounded-lg px-2 lg:px-4"
        @click.prevent="showQRCodeModal = true"
      >
        <svg
          class="h-6 w-6 mx-auto"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
          />
        </svg>
        QR
      </button>

      <button
        v-if="isAuthenticated"
        class="user-menu"
        :class="{ 'scale-110 bg-secondary bg-opacity-80': showUserMenu }"
        @click="showUserMenu = !showUserMenu"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="user-profile-icon"
          :class="showUserMenu ? 'fill-gray-900' : 'fill-white'"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="arrow-icon"
          :class="showUserMenu ? 'stroke-gray-900' : 'stroke-white'"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
    </div>

    <div v-if="showUserMenu" class="top-bar__nav-dropdown">
      <div class="overlay" @click="showUserMenu = false"></div>
      <div class="top-bar__user-info">
        <p
          v-show="userFullname && $route.name !== 'ConfirmData'"
          class="uppercase md:text-sm font-semibold tracking-wider"
        >
          {{ userFullname }}{{ isOneBarOnly ? " - " + assignedBar.name : "" }}
        </p>
        <p class="text-sm md:text-xs">{{ userRole }}</p>
      </div>
      <router-link
        v-if="canSeeAdminPanel"
        class="main-tab"
        :to="{ name: 'Admin_Geral' }"
        @click.native="showUserMenu = false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
          />
        </svg>
        Admin Panel
      </router-link>
      <router-link
        class="main-tab"
        :to="{ name: routeName }"
        @click.native="showUserMenu = false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
        MENU
      </router-link>

      <router-link class="main-tab disabled" to="#">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        Settings
      </router-link>
      <button class="main-tab" @click.prevent="logout">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>
        Sign out
      </button>
    </div>
  </div>
</template>

<script>
import eventBus from "@/eventBus.js";

export default {
  name: "TopBar",

  components: {
    ModalTemplate: () =>
      import(/* webpackChunkName: "modal-template" */ "./ModalTemplate.vue"),
    QrcodeVue: () => import(/* webpackChunkName: "qr-code" */ "qrcode.vue"),
  },

  data() {
    return {
      isOneBarOnly: false,
      assignedBar: null,
      routeName: "Menu",
      isIos: null,
      showQRCodeModal: false,
      size: 150,
      baseURL: process.env.VUE_APP_BASEURL,
      qrCodeURL: null,
      showUserMenu: false,
      showSupportModal: false,
      supportInfo: { mail: null, contact: null },
    };
  },

  computed: {
    displayUserProfile() {
      return !(
        this.$route.name === "Login" ||
        this.$route.name === "NewPlayer31" ||
        this.$route.name === "ConfirmData" ||
        this.$route.name === "NewPassword"
      );
    },

    userFullname() {
      return this.$store.getters["auth/getUserFullname"];
    },

    userRole() {
      return this.$store.getters["auth/getRole"];
    },

    canSeeAdminPanel() {
      if (this.userRole === "admin" || this.userRole === "agentofadmin")
        return true;
      return false;
    },

    isAuthenticated() {
      return this.$store.getters["auth/getRefreshToken"];
    },
  },

  created() {
    eventBus.$on("supportInfo", this.supportInfoUpdate);

    this.qrCodeURL = window.location.href;

    // if (this.$store.getters["auth/getRole"] == "admin") {
    //    this.$router.push({ name: "Admin_Geral" });
    // }

    if (
      this.$store.getters["auth/getAssignedRooms"] &&
      this.$store.getters["auth/getAssignedRooms"].length == 1
    ) {
      this.isOneBarOnly = true;
      this.assignedBar = this.$store.getters["auth/getAssignedRooms"][0];
    }

    let ua = window.navigator.userAgent;
    // In iOS 13 Apple removed "iPad" from the iPad user-agent... So we need to check for touch points as well
    if (
      (ua.toLowerCase().indexOf("macintosh") > -1 &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2) ||
      ua.match(/ipad|iphone|ipod/i)
    ) {
      this.isIos = true;
    } else {
      this.isIos = false;
    }
  },

  beforeDestroy() {
    eventBus.$off("supportInfo");
  },

  methods: {
    supportInfoUpdate(supportInfo) {
      this.supportInfo.mail = supportInfo.mail;
      this.supportInfo.contact = supportInfo.contact;
    },

    async logout() {
      this.showUserMenu = !this.showUserMenu;
      await this.$store.dispatch("auth/cleanUpAuth");
      this.$router.push({ name: "Login" });
    },

    chooseRoute() {
      if (
        this.$router.name !== "NewPlayer31" &&
        this.$store.getters["auth/getRole"] !== "admin"
      ) {
        this.routeName = "'Menu'";
      } else if (this.$store.getters["auth/getRole"] == "admin") {
        this.routeName = "'Admin_Geral'";
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
#top-bar {
  /* height: 7vh; */
  height: $top-bar--height;
  @apply flex items-center bg-gray-900 px-6 w-full shadow-lg;

  /* @screen md {
      height: 5vh;
   } */

  @screen lg {
    @apply px-16;
  }

  video {
    @apply h-8 max-h-full rounded-lg;
  }

  .user-menu {
    transition-duration: 75ms;
    @apply pt-1 flex items-center transform  ease-in rounded-lg px-2 z-50;
    .user-profile-icon {
      transition-duration: 1ms;
      @apply h-7 w-7;
    }

    .arrow-icon {
      transition-duration: 1ms;

      @apply w-3 h-3;
    }

    @media (hover: hover) {
      &:hover {
        @apply scale-110 bg-secondary bg-opacity-80;

        .user-profile-icon {
          @apply fill-gray-900;
        }

        .arrow-icon {
          @apply stroke-gray-900;
        }
      }
    }
  }

  .top-bar__nav-dropdown {
    /* filter: drop-shadow(0 0 3px rgb(0 0 0 / 0.6)); */
    margin-top: $top-bar--height;
    height: calc(100% - $top-bar--height);
    z-index: 9999;
    @apply bg-gray-900 items-stretch w-full rounded-b-lg absolute right-0 top-0 flex flex-col;

    @screen md {
      /* margin-top: 5vh; */
      @apply h-fit w-44 right-6;
    }

    @screen lg {
      @apply right-16;
    }

    .overlay {
      @apply w-full h-full fixed left-0 top-0;
    }

    .top-bar__user-info {
      z-index: 9999;
      @apply border-b border-t py-6 border-gray-600 text-center;

      @screen md {
        @apply text-left pl-3 py-2;
      }
    }

    /* .top-bar__logout-btn {
         @apply uppercase py-3 font-medium tracking-wider text-sm rounded-b-lg;
         &:hover {
            @apply bg-gray-700 text-base py-2.5;
         }
      } */

    .main-tab {
      z-index: 9999;
      @apply flex gap-x-2 py-6 pl-6 uppercase font-medium tracking-wider;

      /* &:hover {
            @apply bg-gray-700 text-sm py-1.5;
         } */

      svg {
        @apply h-5 w-5;
      }

      @screen md {
        @apply py-3 pl-3 text-sm;

        &:hover {
          @apply bg-gray-700 text-base py-2.5;

          svg {
            @apply h-6 w-6;
          }
        }
      }

      /* &:last-child {
          margin-top: auto;
          border-bottom: 0;
          @apply border-t;
        } */
    }

    /* .router-link-active {
         padding-top: 0.77rem;
         padding-bottom: 0.77rem;
         text-shadow: 0 0 1px theme(colors.primary), 0 0 10px black;
         @apply text-primary text-lg;
      } */

    .sub-tab {
      @apply text-center text-sm uppercase py-1 hover:bg-gray-700;
    }
  }
}
</style>
