//TODO - LOGIN
import { httpClient } from "@/helpers/httpClient";
import axios from "axios";

let refreshTokenResponse;

export function login(body) {
  return httpClient().post("auth", body);
}

export function verifyAuth() {
  return httpClient().get("auth/verify");
}

export function resetPassword(body) {
  return httpClient().patch("account/forgot-password", body);
}

export function refreshToken(body) {
  if (!refreshTokenResponse) {
    refreshTokenResponse = axios.post(
      `${process.env.VUE_APP_BACKEND_API_URL}/auth/refresh-token`,
      body
    );
  }
  return refreshTokenResponse;
}

export function loginInPlayerServer(body) {
  return axios.post(
    `${process.env.VUE_APP_PLAYER_SERVER_API_URL}/api/auth`,
    body
  );
}
