// initial state
const state = () => ({
  accessToken: null,
  refreshToken: null,
  deviceId: null,
  userId: null,
  role: null,
  assignedRooms: null,
  userPercentage: null,
  userFullname: null,
});

// getters
const getters = {
  getAccessToken: (state) => state.accessToken,
  getRefreshToken: (state) => state.refreshToken,
  getDeviceId: (state) => state.deviceId,
  getUserId: (state) => state.userId,
  getRole: (state) => state.role,
  getAssignedRooms: (state) => state.assignedRooms,
  getUserPercentage: (state) => state.userPercentage,
  getUserFullname: (state) => state.userFullname,
};

// actions
const actions = {
  updateAccessToken({ commit }, accessToken) {
    commit("mutateAccessToken", accessToken);
  },

  updateRefreshToken({ commit }, refreshToken) {
    commit("mutateRefreshToken", refreshToken);
  },

  updateDeviceId({ commit }, deviceId) {
    commit("mutateDeviceId", deviceId);
  },

  updateUserId({ commit }, userId) {
    commit("mutateUserId", userId);
  },

  updateRole({ commit }, role) {
    commit("mutateRole", role);
  },

  updateAssignedRooms({ commit }, assignedRooms) {
    commit("mutateAssignedRooms", assignedRooms);
  },

  updateUserPercentage({ commit }, userPercentage) {
    commit("setUserPercentage", userPercentage);
  },

  updateUserFullname({ commit }, userFullname) {
    commit("setUserFullname", userFullname);
  },

  setAuthTokens({ commit }, at, rt) {
    commit("mutateAccessToken", at);
    commit("mutateRefreshToken", rt);
  },

  cleanUpAuth({ commit }) {
    commit("mutateAccessToken", null);
    commit("mutateRefreshToken", null);
    commit("mutateUserId", null);
    commit("mutateRole", null);
    commit("mutateAssignedRooms", null);
    commit("setUserFullname", null);
    commit("setUserPercentage", null);
  },
};

// mutations
const mutations = {
  mutateAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },

  mutateRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  mutateDeviceId(state, deviceId) {
    state.deviceId = deviceId;
  },

  mutateUserId(state, userId) {
    state.userId = userId;
  },

  mutateRole(state, role) {
    state.role = role;
  },

  mutateAssignedRooms(state, assignedRooms) {
    state.assignedRooms = assignedRooms;
  },

  setUserPercentage(state, userPercentage) {
    state.userPercentage = userPercentage;
  },
  setUserFullname(state, userFullname) {
    state.userFullname = userFullname;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
