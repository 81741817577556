import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const allRoles = ["admin", "agentofadmin", "manager", "agentofowner", "owner"];

const routes = [
  /* ********************************************************************************************************************************* LOGIN ******************** */
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/ups",
    name: "UPS",
    component: () =>
      import(
        /* webpackChunkName: "ups" */
        "../views/UPS.vue"
      ),
  },
  {
    path: "/",
    name: "Menu",
    meta: {
      requiresAuth: true,
      role: allRoles,
    },
    component: () =>
      import(
        /* webpackChunkName: "menu" */
        "../views/Menu.vue"
      ),
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import(
        /* webpackChunkName: "404" */
        "../views/404.vue"
      ),
  },
  //TODO - UPDATE LOGIC
  {
    path: "/email-confirmation",
    name: "EmailConfirmation",
    meta: {
      requiresAuth: true,
      role: allRoles,
    },
    component: () =>
      import(
        /* webpackChunkName: "email-conf" */
        "@/components/forms/EmailConfirmation.vue"
      ),
  },
  {
    path: "/set-password",
    name: "SetPassword",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "pass" */
        "../views/Register/NewPassword.vue"
      ),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "pass" */
        "../views/Register/NewPassword.vue"
      ),
  },
  /* ********************************************************************************************************************************* REGISTER ******************** */
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/Register/Register.vue"
      ),
    children: [
      {
        path: "new-manager",
        name: "NewManager",
        meta: {
          requiresAuth: true,
          role: ["admin", "agentofadmin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "new-manager" */
            "@/components/forms/NewManager/NewManager.vue"
          ),
      },
      {
        path: "new-owner",
        name: "NewOwner",
        meta: {
          requiresAuth: true,
          role: ["admin", "agentofadmin", "manager", "agentofmanager"],
        },
        component: () =>
          import(
            /* webpackChunkName: "new-owner" */
            "@/components/forms/NewOwner/NewOwner.vue"
          ),
      },
      {
        path: "new-agent",
        name: "NewAgent",
        meta: {
          requiresAuth: true,
          role: ["admin", "manager", "owner"],
        },
        component: () =>
          import(
            /* webpackChunkName: "new-agent" */
            "@/components/forms/NewAgent/NewAgent.vue"
          ),
      },
      {
        path: "new-player-3",
        name: "NewPlayer3",
        meta: {
          requiresAuth: true,
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "new-player-3" */
            "@/components/forms/NewPlayer3/NewPlayer3.vue"
          ),
      },
      {
        path: "player",
        name: "NewPlayer31",
        component: () =>
          import(
            /* webpackChunkName: "new-player-31" */
            "@/components/forms/NewPlayer3/NewPlayer31.vue"
          ),
      },
    ],
  },
  {
    path: "/confirm-data",
    name: "ConfirmData",
    component: () =>
      import(
        /* webpackChunkName: "confirm-data" */
        "../views/Register/ConfirmData.vue"
      ),
  },

  /* ******************************************************************************************************************* AGENT ADMIN - ID APPROVAL ******************** */
  // {
  //    path: "/id-approval",
  //    meta: {
  //       requiresAuth: true,
  //       role: ["agentofadmin"],
  //    },
  //    component: () => import("@/views/IdApproval.vue"),
  //    children: [
  //       {
  //          path: "/id-approval",
  //          name: "IdApproval",
  //          component: () => import("../components/IdApproval/IdApproval.vue"),
  //       },
  //       {
  //          path: "/id-approval/:playerId",
  //          name: "IdApprovalInfo",
  //          props: true,
  //          component: () =>
  //             import("../components/IdApproval/IdApprovalInfo.vue"),
  //       },
  //       {
  //          path: "/id-approval/:userId/rejected",
  //          name: "IdApprovalRejected",
  //          props: true,
  //          component: () =>
  //             import("../components/IdApproval/IdApprovalRejected.vue"),
  //       },
  //    ],
  // },
  /* ************************************************************************************************************************************* GERAL ******************** */
  {
    path: "",
    name: "Geral",
    meta: {
      requiresAuth: true,
      //    role: ["admin", "agentofadmin", "manager", "agentofowner", "owner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "geral" */
        "../views/Geral.vue"
      ),
    children: [
      {
        path: "/bars",
        name: "Bars",
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "rooms" */
            "../views/Bars/Bars.vue"
          ),
      },
      {
        name: "Bar",
        path: "/bars/:roomId",
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "room" */
            "../views/Bars/Bar.vue"
          ),
      },
      {
        name: "BarInfo",
        path: "/bars/:roomId/info",
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "room-info" */
            "../views/Bars/BarInfo.vue"
          ),
      },
      {
        name: "BarDocs",
        path: "/bars/:roomId/documents",
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "room-docs" */
            "../views/Bars/Docs.vue"
          ),
      },
      {
        name: "BarConfig",
        path: "/bars/:roomId/config",
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "room-config" */
            "../views/Bars/BarConfig.vue"
          ),
      },
      {
        path: "bars/:roomId/games",
        name: "Games_Room",
        props: true,
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "room-games" */
            "../views/Bars/BarGames.vue"
          ),
      },
      {
        path: "bars/:roomId/game-store",
        name: "BarGamestore",
        props: true,
        meta: {
          role: allRoles,
        },
        component: () =>
          import(/* webpackChunkName: "game-store" */ "@/views/GameStore.vue"),
      },
      {
        name: "BarCabinets",
        path: "/bars/:roomId/cabinets",
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "rooms" */
            "../views/Cabinets/BarCabinets.vue"
          ),
      },
      {
        name: "BarCabinet",
        path: "/bars/:roomId/cabinets/:cabinetId",
        props: true,
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "rooms" */
            "../views/Cabinets/BarCabinet.vue"
          ),
      },
      // {
      //   path: "/game-store",
      //   name: "GameStore",
      //   meta: {
      //     role: allRoles,
      //   },
      //   component: () =>
      //     import(/* webpackChunkName: "game-store" */ "@/views/GameStore.vue"),
      // },
      {
        path: "/players",
        name: "Players",
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "players" */
            "../views/Players/Players.vue"
          ),
      },
      {
        path: "/players/:playerId",
        name: "PlayerInfo",
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "players" */
            "../views/Players/PlayerInfo.vue"
          ),
      },
      //TODO - agent of owner not allowed on game store (?)
      {
        path: "/game-store",
        name: "GameStore",
        meta: {
          role: allRoles,
        },
        component: () =>
          import(/* webpackChunkName: "game-store" */ "@/views/GameStore.vue"),
      },
      {
        path: "/agents",
        name: "Agents",
        meta: {
          role: allRoles,
        },
        component: () =>
          import(/* webpackChunkName: "agents" */ "../views/Agents/Agents.vue"),
      },
      {
        path: "/agents/:agentId",
        name: "AgentInfo",
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "agents" */
            "../views/Agents/AgentInfo.vue"
          ),
      },
      {
        path: "/id-approval",
        name: "Id-approval",
        meta: {
          requiresAuth: true,
          role: ["admin", "agentofadmin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "id-approval" */
            "../components/IdApproval/IdApproval.vue"
          ),
      },
      {
        path: "/id-approval/:playerId",
        name: "IdApprovalInfo",
        meta: {
          requiresAuth: true,
          role: ["admin", "agentofadmin"],
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "id-approval" */
            "../components/IdApproval/IdApprovalInfo.vue"
          ),
      },
      {
        path: "/id-approval/:userId/rejected",
        name: "IdApprovalRejected",
        meta: {
          requiresAuth: true,
          role: ["admin", "agentofadmin"],
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "id-approval" */
            "../components/IdApproval/IdApprovalRejected.vue"
          ),
      },
      {
        path: "sessions/players/:playerId",
        name: "Sessions_Player",
        props: true,
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "sessions" */
            "../views/admin/sessions.vue"
          ),
      },
      {
        path: "sessions/machines/:machineId",
        name: "Sessions_Machine",
        props: true,
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "sessions" */
            "../views/admin/sessions.vue"
          ),
      },
      {
        path: "sessions/games/:gameId",
        name: "Sessions_Game",
        props: true,
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "sessions" */
            "../views/admin/sessions.vue"
          ),
      },
      {
        path: "sessions/bundles/:bundleId",
        name: "Sessions_Bundle",
        props: true,
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "sessions" */
            "../views/admin/sessions.vue"
          ),
      },
      {
        path: "sessions/rooms/:roomId",
        name: "Sessions_Room",
        props: true,
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "sessions" */
            "../views/admin/sessions.vue"
          ),
      },
      {
        path: "sessions/rooms/:roomId/game/:gameId",
        name: "Sessions_RoomGame",
        props: true,
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "sessions" */
            "../views/admin/sessions.vue"
          ),
      },
      {
        path: "sessions/rooms/:roomId/bundle/:bundleId",
        name: "Sessions_RoomBundle",
        props: true,
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "sessions" */
            "../views/admin/sessions.vue"
          ),
      },
      {
        path: "sessions",
        name: "Sessions",
        props: true,
        meta: {
          role: allRoles,
        },
        component: () =>
          import(
            /* webpackChunkName: "sessions" */
            "../views/admin/sessions.vue"
          ),
      },
    ],
  },

  /* ******************************************************************************************************************************* AGENT - DEMO ******************** */
  {
    path: "/demo",
    name: "Demo",
    meta: {
      requiresAuth: true,
      role: ["agentofowner", "manager"],
    },
    component: () =>
      import(
        /* webpackChunkName: "demo" */

        "@/views/Demo.vue"
      ),
  },

  /* *************************************************************************************************************************************** ADMIN ******************** */

  {
    path: "/admin",
    name: "Admin_Main",
    meta: {
      requiresAuth: true,
      role: ["admin", "agentofadmin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-main" */
        "@/views/admin/main.vue"
      ),
    children: [
      {
        path: "geral",
        name: "Admin_Geral",
        meta: {
          role: ["admin", "agentofadmin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-geral" */
            "../views/admin/geral.vue"
          ),
      },
      {
        path: "users/:role",
        name: "Admin_Users",
        meta: {
          role: ["admin", "agentofadmin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "users" */
            "../views/admin/users.vue"
          ),
      },
      {
        path: "rooms/all",
        name: "Admin_Rooms",
        meta: {
          role: ["admin", "agentofadmin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "rooms" */
            "../views/admin/rooms.vue"
          ),
      },
      {
        path: "rooms/templates",
        name: "Admin_Rooms_Templates",
        meta: {
          role: ["admin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "room-templates" */
            "../views/admin/rooms/AdminRoomsTemplates.vue"
          ),
      },
      {
        path: "players",
        name: "Admin_Players",
        meta: {
          role: ["admin", "agentofadmin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "players" */
            "../views/admin/players.vue"
          ),
      },
      {
        path: "tokens",
        name: "AdminTokens",
        meta: {
          role: ["admin", "agentofadmin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "tokens" */
            "../views/admin/AdminTokens.vue"
          ),
      },
      {
        path: "chat",
        name: "AdminChat",
        meta: {
          role: ["admin", "agentofadmin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "chat" */
            "../views/admin/Chat.vue"
          ),
      },
      {
        path: "jobs",
        name: "Admin_Jobs",
        meta: {
          role: ["admin", "agentofadmin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "jobs" */
            "../views/admin/jobs.vue"
          ),
      },
      {
        path: "logs",
        name: "Admin_Logs",
        meta: {
          role: ["admin", "agentofadmin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "logs" */
            "../views/admin/logs.vue"
          ),
      },
      {
        path: "mail-boxes",
        name: "Admin_MailBoxes",
        meta: {
          role: ["admin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "mailboxes" */
            "../views/admin/mailboxes.vue"
          ),
      },
      // {
      //    path: "games",
      //    name: "Admin_Games",
      //    component: () => import("../views/admin/games.vue"),
      // },
      {
        path: "sessions",
        name: "Admin_Sessions",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "sessions" */
            "../views/admin/sessions.vue"
          ),
      },
      {
        path: "game-store/games/external/:id",
        name: "Admin_GameStore_ExternalGame",
        component: () =>
          import(
            /* webpackChunkName: "game-info" */
            "../views/admin/gameStore/gameInfo.vue"
          ),
      },
      {
        path: "game-store/games/external",
        name: "Admin_GameStore_ExternalGames",
        component: () =>
          import(
            /* webpackChunkName: "external-games" */
            "../views/admin/gameStore/externalGames.vue"
          ),
      },
      {
        path: "game-store/templates/create",
        name: "Admin_GameStore_CreateBundleTemplate",
        component: () =>
          import(
            /* webpackChunkName: "bundle-template-create" */
            "../views/admin/gameStore/bundles/BundleTemplate.vue"
          ),
      },
      {
        path: "game-store/templates/:id/edit",
        name: "Admin_GameStore_EditBundleTemplate",
        component: () =>
          import(
            /* webpackChunkName: "bundles-template--edit" */
            "../views/admin/gameStore/bundles/BundleTemplate.vue"
          ),
      },
      {
        path: "game-store/templates/:id",
        name: "Admin_GameStore_BundleTemplateInfo",
        component: () =>
          import(
            /* webpackChunkName: "bundle-template-info" */
            "../views/admin/gameStore/bundles/BundleTemplate.vue"
          ),
      },
      {
        path: "game-store/templates",
        name: "Admin_GameStore_ListBundleTemplate",
        component: () =>
          import(
            /* webpackChunkName: "bundle-templates-list" */
            "../views/admin/gameStore/bundles/listBundleTemplates.vue"
          ),
      },
      {
        path: "game-store/bundles/create",
        name: "Admin_GameStore_CreateBundleDynamic",
        component: () =>
          import(
            /* webpackChunkName: "bundles-create-dynamic" */
            "../views/admin/gameStore/bundles/createBundleDynamic.vue"
          ),
      },
      {
        path: "game-store/games/:id",
        name: "Admin_GameStore_Game",
        component: () =>
          import(
            /* webpackChunkName: "game-info" */
            "../views/admin/gameStore/gameInfo.vue"
          ),
      },
      {
        path: "game-store/bundles/:id",
        name: "Admin_GameStore_Bundle",
        component: () =>
          import(
            /* webpackChunkName: "bundle-info" */
            "../views/admin/gameStore/bundles/bundleInfo.vue"
          ),
      },
      {
        path: "game-store/games",
        name: "Admin_GameStore_Games",
        component: () =>
          import(
            /* webpackChunkName: "games" */
            "../views/admin/gameStore.vue"
          ),
      },
      {
        path: "game-store/bundles",
        name: "Admin_GameStore_Bundles",
        component: () =>
          import(
            /* webpackChunkName: "bundles" */
            "../views/admin/gameStore.vue"
          ),
      },
      {
        path: "skins",
        name: "Admin_Skins",
        component: () =>
          import(
            /* webpackChunkName: "skins" */
            "../views/admin/skins.vue"
          ),
      },
      {
        path: "sms",
        name: "Admin_Sms",
        component: () =>
          import(
            /* webpackChunkName: "sms" */
            "../views/admin/sms.vue"
          ),
      },
      {
        path: "deploy",
        name: "Admin_Deploy",
        component: () =>
          import(
            /* webpackChunkName: "deploy" */
            "../views/admin/deploy.vue"
          ),
      },
      {
        path: "populate-bars",
        name: "Admin_Populate_Bars",
        component: () =>
          import(
            /* webpackChunkName: "populate-bars" */
            "../views/admin/PopulateBars.vue"
          ),
      },
      {
        path: "notifications",
        name: "Admin_Notifications",
        component: () =>
          import(
            /* webpackChunkName: "notifications" */
            "../views/admin/Notifications.vue"
          ),
      },
    ],
  },
  // {
  //   path: "/test-table",
  //   name: "TestTable",
  //   component: () => import("../views/TestTable.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const role = store.getters["auth/getRole"];
  let isAuthenticated = !!store.getters["auth/getRefreshToken"] && role;

  if (!requiresAuth) {
    if (to.name == "Login" && isAuthenticated) {
      return next({ name: "Menu" });
    } else {
      return next();
    }
  }

  let isAuthorized = to.matched.some((record) => {
    let hasParent = !!record.parent;
    let requiresAuth = record.meta.requiresAuth;
    let parentRequiresAuth = hasParent
      ? record.parent.meta.requiresAuth
      : false;

    if (!parentRequiresAuth && !requiresAuth) {
      return true;
    }

    let parentHasRole =
      hasParent && record.parent.meta.role
        ? record.parent.meta.role.includes(role)
        : false;
    let hasRole = record.meta.role ? record.meta.role.includes(role) : false;

    if (parentHasRole || hasRole) {
      return true;
    }
  });

  if (!isAuthenticated || !isAuthorized) {
    return next({
      name: "Login",
      params: {
        nextUrl: to.fullPath,
      },
    });
  }

  // redirect login to home if auth'ed users
  if (to.name == "Login") {
    next({ name: "Menu" });
  }

  return next();
});

export default router;
