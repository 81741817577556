import Vue from "vue";
import { getSkinNameByUserId } from "../../services/api/registerService";

// initial state

const state = () => ({
  skinName: null,
  skinUrl: null,
});

//getters
const getters = {};

// actions
const actions = {
  getSkinNameByUserId({ commit }, userId) {
    console.log("dispatching skinname");
    getSkinNameByUserId(userId)
      .then((res) => {
        console.log("sucesso");
        commit("mutateSkinName", res.data.skin.name);
        commit("mutateSkinUrl", res.data.skin.username);
      })
      .catch((err) => {
        console.error(err);
        commit("mutateSkinName", "CASINOMANIA");
        commit("mutateSkinUrl", "https://www.casinomania.it");
      });
  },
};

// mutations
const mutations = {
  mutateSkinName(state, skinName) {
    if (state.skinName !== skinName) {
      state.skinName = skinName;
    }
  },

  mutateSkinUrl(state, skinUrl) {
    state.skinUrl = skinUrl;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
