var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      !_vm.hideTopBar ? _c("TopBar") : _vm._e(),
      _c("router-view", {
        staticClass: "router-view",
        style: _vm.routerViewHeight,
      }),
      !_vm.hideFooter
        ? _c("Footer", {
            attrs: { "is-standalone-mode": _vm.isStandaloneMode },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }