// initial state
const state = () => ({
  registerInfo: [],
  contractJob: { contractId: null, pages: null, roomId: null, agentId: null },
});

// getters
const getters = {
  getRegisterInfo: (state) => state.registerInfo,
  getContractJob: (state) => state.contractJob,
};

// actions
const actions = {
  updateRegisterInfo({ commit }, registerInfo) {
    commit("setRegisterInfo", registerInfo);
  },
  updateContractJob({ commit }, contractJob) {
    commit("setContractJob", contractJob);
  },
  ////////
  updateContractId({ commit }, contractId) {
    commit("setContractId", contractId);
  },
  updateRoomId({ commit }, roomId) {
    commit("setRoomId", roomId);
  },
  updateAgentId({ commit }, agentId) {
    commit("setAgentId", agentId);
  },
  ///////
  resetRegisterInfo({ commit }) {
    commit("resetRegisterInfo");
  },
  resetContractJob({ commit }) {
    commit("resetContractJob");
  },
};

// mutations
const mutations = {
  setRegisterInfo(state, registerInfo) {
    state.registerInfo = registerInfo;
  },
  setContractJob(state, contractJob) {
    state.contractJob = contractJob;
  },
  ///////////////////////////////////////////////////////////////

  setContractId(state, contractId) {
    state.contractJob.contractId = contractId;
  },

  setRoomId(state, roomId) {
    state.contractJob.roomId = roomId;
  },

  setAgentId(state, agentId) {
    state.contractJob.agentId = agentId;
  },

  ///////////////////////////////////////////////////////////////
  resetRegisterInfo(state) {
    state.registerInfo = [];
  },
  resetContractJob(state) {
    state.contractJob = {
      contractId: null,
      roomId: null,
      agentId: null,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
