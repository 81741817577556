var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        _vm.$route.name !== "Login" ? "justify-between" : "justify-center",
      ],
      attrs: { id: "top-bar" },
    },
    [
      _vm.showQRCodeModal
        ? _c("ModalTemplate", {
            staticClass: "z-50",
            on: {
              close: function ($event) {
                _vm.showQRCodeModal = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h3", [_vm._v("Proseguire su un altro dispositivo")]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("p", [
                        _vm._v(
                          "Accedi a questa pagina su un altro dispositivo:"
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              _vm.showQRCodeModal = false
                            },
                          },
                        },
                        [
                          _c("qrcode-vue", {
                            staticClass:
                              "p-4 bg-white qr-code rounded-xl shadow-xl w-fit mx-auto mt-4",
                            attrs: {
                              value: _vm.qrCodeURL,
                              size: _vm.size,
                              "render-as": "svg",
                              foreground: "#000000",
                              background: "white",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              937898848
            ),
          })
        : _vm._e(),
      _vm.showSupportModal
        ? _c("ModalTemplate", {
            staticClass: "z-50",
            on: {
              close: function ($event) {
                _vm.showSupportModal = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [_c("h3", [_vm._v("Supporto clienti")])]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("p", [
                        _vm._v(
                          "\n        Per maggiori informazioni non esitare a contattare il nostro supporto\n        clienti:\n      "
                        ),
                      ]),
                      _c("div", { staticClass: "space-y-2 mt-4" }, [
                        _vm.supportInfo.mail
                          ? _c(
                              "a",
                              {
                                staticClass: "flex gap-x-4",
                                attrs: {
                                  href: `mailto:${_vm.supportInfo.mail}`,
                                  target: "_blank",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "h-6 w-6",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      fill: "none",
                                      viewBox: "0 0 24 24",
                                      stroke: "currentColor",
                                      "stroke-width": "1.5",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        d: "M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  _vm._s(_vm.supportInfo.mail) + "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.supportInfo.contact
                          ? _c(
                              "a",
                              {
                                staticClass: "flex gap-x-4",
                                attrs: {
                                  href: `tel:${_vm.supportInfo.contact}`,
                                  target: "_blank",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "h-6 w-6",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      fill: "none",
                                      viewBox: "0 0 24 24",
                                      stroke: "currentColor",
                                      "stroke-width": "1.5",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        d: "M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  _vm._s(_vm.supportInfo.contact) + "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1422935364
            ),
          })
        : _vm._e(),
      (_vm.$route.name == "ConfirmData" || _vm.$route.name == "NewPlayer31") &&
      !_vm.isAuthenticated &&
      _vm.supportInfo.mail &&
      _vm.supportInfo.contact
        ? _c(
            "button",
            {
              staticClass:
                "text-xs h-8 tracking-wide flex items-center gap-x-1.5 font-medium uppercase border border-secondary rounded-lg px-2 lg:px-4",
              class: { "ml-auto": _vm.$route.name == "NewPlayer31" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.showSupportModal = true
                },
              },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "w-5 h-5",
                  staticStyle: { "enable-background": "new 0 0 442 442" },
                  attrs: {
                    version: "1.1",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink",
                    x: "0px",
                    y: "0px",
                    viewBox: "0 0 442 442",
                    "xml:space": "preserve",
                    fill: "#fff",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M431.706,232.827h-1.108c-3.863-17.348-17.544-31.03-34.892-34.892v-23.229C395.706,78.373,317.333,0,221,0S46.294,78.373,46.294,174.706v23.229c-17.348,3.863-31.03,17.544-34.892,34.892h-1.108c-5.523,0-10,4.478-10,10s4.477,10,10,10h1.108c3.962,17.796,18.257,31.735,36.242,35.173c4.159,17.647,20.027,30.827,38.926,30.827c22.056,0,40-17.944,40-40v-72c0-22.056-17.944-39.999-40-39.999c-7.384,0-14.303,2.019-20.246,5.523C67.589,88.128,136.482,20,221,20c84.464,0,153.325,68.042,154.674,152.191c-5.881-3.404-12.698-5.363-19.968-5.363c-22.056,0-40,17.943-40,39.999v72c0,22.056,17.944,40,40,40c18.918,0,34.799-13.205,38.938-30.879c6.229-1.232,12.007-3.724,17.062-7.201v44.167c0,18.786-9.134,36.63-25.72,50.244C368.827,389.243,345.999,397,321.706,397h-64.907c-2.082-15.648-9.245-25-19.726-25h-37.5c-19.299,0-35,15.701-35,35s15.701,35,35,35h37.5c10.482,0,17.645-9.352,19.726-25h64.907c60.654,0,110-41.31,110-92.086v-72.087c5.523,0,10-4.478,10-10S437.229,232.827,431.706,232.827z M66.57,206.827c0-11.027,8.972-19.999,20-19.999c11.028,0,20,8.972,20,19.999v72c0,11.028-8.972,20-20,20c-11.028,0-20-8.972-20-20c0-5.522-4.477-10-10-10c-0.047,0-0.092,0.006-0.138,0.007c-0.047,0-0.092-0.007-0.138-0.007c-14.336,0-26-11.663-26-26c0-10.9,6.744-20.248,16.276-24.106v25.996c0,5.522,4.477,10,10,10s10-4.478,10-10V206.827z M234.938,422h-35.366c-8.271,0-15-6.729-15-15s6.729-15,15-15h35.366c1.049,2.163,2.489,7.271,2.489,15S235.987,419.837,234.938,422z M385.706,268.827c-5.523,0-10,4.478-10,10c0,11.028-8.972,20-20,20s-20-8.972-20-20v-72c0-11.027,8.972-19.999,20-19.999s20,8.972,20,19.999v37.89c0,5.522,4.477,10,10,10s10-4.478,10-10V218.83c9.387,3.926,16,13.203,16,23.997C411.706,257.164,400.042,268.827,385.706,268.827z",
                    },
                  }),
                ]
              ),
              _vm._v("\n    supporto\n  "),
            ]
          )
        : _vm._e(),
      _vm.isAuthenticated || _vm.$route.name == "ConfirmData"
        ? _c("div", { staticClass: "flex ml-auto items-center" }, [
            _vm.$route.name == "ConfirmData"
              ? _c(
                  "button",
                  {
                    staticClass:
                      "text-xs h-8 tracking-wide flex items-center gap-x-1.5 font-medium uppercase border border-secondary rounded-lg px-2 lg:px-4",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.showQRCodeModal = true
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "h-6 w-6 mx-auto",
                        attrs: {
                          fill: "none",
                          viewBox: "0 0 24 24",
                          stroke: "currentColor",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "1.5",
                            d: "M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z",
                          },
                        }),
                      ]
                    ),
                    _vm._v("\n      QR\n    "),
                  ]
                )
              : _vm._e(),
            _vm.isAuthenticated
              ? _c(
                  "button",
                  {
                    staticClass: "user-menu",
                    class: {
                      "scale-110 bg-secondary bg-opacity-80": _vm.showUserMenu,
                    },
                    on: {
                      click: function ($event) {
                        _vm.showUserMenu = !_vm.showUserMenu
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "user-profile-icon",
                        class: _vm.showUserMenu
                          ? "fill-gray-900"
                          : "fill-white",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 20 20",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d: "M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z",
                            "clip-rule": "evenodd",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "svg",
                      {
                        staticClass: "arrow-icon",
                        class: _vm.showUserMenu
                          ? "stroke-gray-900"
                          : "stroke-white",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          "stroke-width": "2",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            d: "M19 9l-7 7-7-7",
                          },
                        }),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.showUserMenu
        ? _c(
            "div",
            { staticClass: "top-bar__nav-dropdown" },
            [
              _c("div", {
                staticClass: "overlay",
                on: {
                  click: function ($event) {
                    _vm.showUserMenu = false
                  },
                },
              }),
              _c("div", { staticClass: "top-bar__user-info" }, [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.userFullname && _vm.$route.name !== "ConfirmData",
                        expression:
                          "userFullname && $route.name !== 'ConfirmData'",
                      },
                    ],
                    staticClass:
                      "uppercase md:text-sm font-semibold tracking-wider",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.userFullname) +
                        _vm._s(
                          _vm.isOneBarOnly ? " - " + _vm.assignedBar.name : ""
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _c("p", { staticClass: "text-sm md:text-xs" }, [
                  _vm._v(_vm._s(_vm.userRole)),
                ]),
              ]),
              _vm.canSeeAdminPanel
                ? _c(
                    "router-link",
                    {
                      staticClass: "main-tab",
                      attrs: { to: { name: "Admin_Geral" } },
                      nativeOn: {
                        click: function ($event) {
                          _vm.showUserMenu = false
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            fill: "none",
                            viewBox: "0 0 24 24",
                            stroke: "currentColor",
                            "stroke-width": "2",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              d: "M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z",
                            },
                          }),
                        ]
                      ),
                      _vm._v("\n      Admin Panel\n    "),
                    ]
                  )
                : _vm._e(),
              _c(
                "router-link",
                {
                  staticClass: "main-tab",
                  attrs: { to: { name: _vm.routeName } },
                  nativeOn: {
                    click: function ($event) {
                      _vm.showUserMenu = false
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        fill: "none",
                        viewBox: "0 0 24 24",
                        stroke: "currentColor",
                        "stroke-width": "2",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          d: "M4 6h16M4 12h16M4 18h16",
                        },
                      }),
                    ]
                  ),
                  _vm._v("\n      MENU\n    "),
                ]
              ),
              _c(
                "router-link",
                { staticClass: "main-tab disabled", attrs: { to: "#" } },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        fill: "none",
                        viewBox: "0 0 24 24",
                        stroke: "currentColor",
                        "stroke-width": "2",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          d: "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          d: "M15 12a3 3 0 11-6 0 3 3 0 016 0z",
                        },
                      }),
                    ]
                  ),
                  _vm._v("\n      Settings\n    "),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "main-tab",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.logout.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        fill: "none",
                        viewBox: "0 0 24 24",
                        stroke: "currentColor",
                        "stroke-width": "2",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          d: "M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1",
                        },
                      }),
                    ]
                  ),
                  _vm._v("\n      Sign out\n    "),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }