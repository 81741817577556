import { httpClient } from "@/helpers/httpClient";

const basePath = "users";

export function troubleReport(body) {
  return httpClient().post(`report-trouble`, body);
}

export function rescueConfirmationLink(body) {
  return httpClient().post(`players/confirmation-link-rescue`, body);
}

//TODO THIS FUNCTION IS REPETED DOWN THERE, IN THE FUTURE KEEP JUST THIS ONE AND UPDATE WHATS DEPENDING ON THE OTHER
export function sendOtpToContact(prefix, contact, userId) {
  return httpClient().post(`/phone/otp?userId=${userId}`, {
    contact,
    prefix,
  });
}

export function getItalyRegions() {
  return httpClient().get(`regions`);
}

export function getRegistryStateIds() {
  return httpClient().get(`registry-states`);
}

export function createPVR(userId, body) {
  return httpClient().post(`${basePath}/${userId}/pvr`, body);
}

export function createAgent(userId, body) {
  return httpClient().post(`${basePath}/${userId}/agents`, body);
}

export function createPlayer(userId, roomId, body) {
  if (roomId) {
    return httpClient().post(
      `${basePath}/${userId}/rooms/${roomId}/players/signup/ocr`,
      body
    );
  } else {
    return httpClient().post(`${basePath}/${userId}/players/signup/ocr`, body);
  }
}

export function createRoom(userId, body) {
  return httpClient().post(`${basePath}/${userId}/rooms`, body);
}

/* this request is also made on PopulateBars.vue "hardcoded" */
export function getContract(userId, contractId) {
  return httpClient().get(`${basePath}/${userId}/contracts/${contractId}`, {
    responseType: "blob",
  });
}

export function uploadSignaturePVR(userId, roomId, contractId, body) {
  return httpClient().patch(
    `${basePath}/${userId}/rooms/${roomId}/contracts/${contractId}/signature`,
    body,
    { enctype: "multipart/form-data" }
  );
}

export function uploadSignatureAgent(userId, agentId, contractId, body) {
  return httpClient().patch(
    `${basePath}/${userId}/agents/${agentId}/contracts/${contractId}/signature`,
    body,
    { enctype: "multipart/form-data" }
  );
}

export function uploadFiles(userId, roomId, body) {
  return httpClient().post(
    `${basePath}/${userId}/rooms/${roomId}/files`,
    body,
    { enctype: "multipart/form-data" }
  );
}

export function getPendingPlayers(userId) {
  return httpClient().get(`${basePath}/${userId}/players/not-validated`);
}

export function getPendingPlayer(userId, playerId) {
  return httpClient().get(`${basePath}/${userId}/players/${playerId}/info`);
}

export function getPlayerFiles(userId, playerId) {
  return httpClient().get(`${basePath}/${userId}/players/${playerId}/files`);
}

export function getConfirmDataLink(playerId) {
  return httpClient().get(`players/${playerId}/confirmation-link`);
}

export function sendDemoEmail(email, userId) {
  return httpClient().post(
    `${basePath}/${userId}/email/send-player-demo`,
    email
  );
}

export function sendDemoSMS(contact, prefix, userId) {
  return httpClient().post(`${basePath}/${userId}/contact/send-player-demo`, {
    contact,
    prefix,
  });
}

export function sendEmailToNewPlayer3(userId, roomId, email) {
  if (roomId) {
    return httpClient().post(
      `${basePath}/${userId}/rooms/${roomId}/email/player-registry`,
      email
    );
  }
  return httpClient().post(
    `${basePath}/${userId}/email/player-registry`,
    email
  );
}

export function sendSMSToNewPlayer3(userId, roomId, contact) {
  if (roomId) {
    return httpClient().post(
      `${basePath}/${userId}/rooms/${roomId}/contact/player-registry`,
      contact
    );
  }
  return httpClient().post(
    `${basePath}/${userId}/contact/player-registry`,
    contact
  );
}

export function setPassword(token, body) {
  return httpClient({
    headers: { authorizationToken: token },
  }).patch(`/account/password`, body);
}

export default {
  createAgent,
  createPlayer,
  createPVR,
  createRoom,
  getContract,
  uploadSignaturePVR,
  uploadSignatureAgent,
  uploadFiles,
  getPendingPlayers,
  getPendingPlayer,
  getPlayerFiles,
  getConfirmDataLink,
  sendDemoEmail,
  sendDemoSMS,
  sendEmailToNewPlayer3,
  sendSMSToNewPlayer3,
  setPassword,
  getSkinNameByUserId,

  confirmPlayer(userId, playerId, body) {
    return httpClient().post(
      `${basePath}/${userId}/players/${playerId}/confirm-registration`,
      body
    );
  },

  checkPlayerRegistrationErrors(userId, playerId, body) {
    return httpClient().post(
      `${basePath}/${userId}/players/${playerId}/check-registration-errors`,
      body
    );
  },

  checkPlayerRegistrationErrorsCD(token, playerId, body) {
    return httpClient({
      headers: { authorizationToken: token },
    }).post(`players/${playerId}/check-registration-errors`, body);
  },

  rejectPlayer(userId, playerId, reason, body) {
    if (body) {
      return httpClient().post(
        `${basePath}/${userId}/players/${playerId}/rejects/${reason}`,
        { other: body }
      );
    } else {
      return httpClient().post(
        `${basePath}/${userId}/players/${playerId}/rejects/${reason}`
      );
    }
  },

  getPlayerInfo(token, playerId) {
    return httpClient({
      headers: { authorizationToken: token },
    }).get(`/players/${playerId}/registration-info`);
  },

  getSkinLink(token, playerId) {
    return httpClient({
      headers: { authorizationToken: token },
    }).get(`/players/${playerId}/registered-skin-link`);
  },

  confirmOrUpdatePlayer(token, playerId, body) {
    return httpClient({
      headers: { authorizationToken: token },
    }).post(`/players/${playerId}/confirm-or-update-registration`, body);
  },

  rejectedByPlayer(token, playerId) {
    return httpClient({
      headers: { authorizationToken: token },
    }).delete(`/players/${playerId}/cancel-registration`);
  },

  checkValidAddress(body) {
    return httpClient().post(`/check-valid-address`, body);
  },

  sendOtpToContact(prefix, contact, userId) {
    return httpClient().post(`/phone/otp?userId=${userId}`, {
      contact,
      prefix,
    });
  },

  sendOtpToEmail(email, type, userId) {
    return httpClient().post(`/email/otp?type=${type}&userId=${userId}`, {
      email,
    });
  },

  sendOtpToContactAndEmail(prefix, contact, email, userId) {
    return httpClient().post(
      `/phone/email/otp?type=verification&userId=${userId}`,
      { prefix, contact, email }
    );
  },

  validateOtp(body) {
    return httpClient().post(`/validate-otp`, body);
  },

  checkIfPlayerIsCreated(playerId) {
    return httpClient().head(`/players/${playerId}`);
  },

  confirmPlayerToAgent(processId) {
    return httpClient().post(`/process/${processId}/confirm`);
  },

  canProcess(processId) {
    return httpClient().post(`/process/${processId}/check`);
  },

  checkIfSupportIsOn() {
    return httpClient().get(`/settings/registerPlayer`);
  },
  checkIfRegisterIsOn() {
    return httpClient().get(`/status/registerPlayer`);
  },
  checkUser(userId) {
    return httpClient().get(`/users/${userId}`);
  },
};

export function getSkinNameByUserId(userId) {
  return httpClient().get(`/users/${userId}/skin`);
}

export function getFiscalCode(userId, body) {
  /* body: firstName, lastName, gender, dateOfBirth, placeOfBirth, provinceOfBirth, nationality */
  /* body: firstName, lastName, gender, dateOfBirth, placeOfBirth, provinceOfBirth, nationality, ISTATcode */
  return httpClient().post(`${basePath}/${userId}/fiscal-code`, body);
}

export function doesNicknameAlreadyExists(token, nickname, playerId) {
  return httpClient({
    headers: { authorizationToken: token },
  }).head(`players/nickname/${nickname}?playerId=${playerId}`);
}

export function skipValidation(userId, playerId) {
  return httpClient().post(
    `users/${userId}/players/${playerId}/skip-validation`
  );
}
